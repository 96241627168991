var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"light-blue lighten-5 pb-16 px-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0 d-flex justify-center align-center"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","sm":"11","md":"9"}},[_c('v-card',{staticClass:"white px-auto",attrs:{"elevation":"0"}},[_c('v-stepper',[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":"","complete-icon":"mdi-airplane"}},[_vm._v(" TRIP INFO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" CHOOSE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" PURCHASE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" DOWNLOAD YOUR POLICY ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center mt-0 pb-16"},[_c('v-col',{staticClass:"mt-0 pt-1",attrs:{"cols":"12","sm":"11","md":"9"}},[_c('v-card',{staticClass:"mt-0"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",staticClass:"px-8 font-weight-bold",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"title-text text-center mt-2"},[_vm._v(" Trip Info ")])])],1),_c('v-row',{staticClass:"d-flex justify-center mb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Destination ")])])],1),_c('v-row',{staticClass:"d-flex justify-center mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('validation-provider',{attrs:{"name":"Destination","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"outlined":"","color":"primary","items":_vm.countries,"error-messages":errors,"label":"I'm going to"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'destination',
                        1,
                        'American Travel Survey v1'
                      )}},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Dates ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.startDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"From","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'startDate',
                            2,
                            'American Travel Survey v1'
                          )}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.nowDate,"max":_vm.maxDate},on:{"change":_vm.setEndDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false;
                        _vm.clickedFieldGlobal(
                          'cancelStartDate',
                          3,
                          'American Travel Survey v1'
                        );}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog1.save(_vm.startDate);
                        _vm.clickedFieldGlobal(
                          'okStartDate',
                          4,
                          'American Travel Survey v1'
                        );}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.endDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"To","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'endDate',
                            5,
                            'American Travel Survey v1'
                          )}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minEndDate,"max":_vm.maxDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false;
                        _vm.clickedFieldGlobal(
                          'cancelEndDate',
                          6,
                          'American Travel Survey v1'
                        );}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog2.save(_vm.endDate);
                        _vm.clickedFieldGlobal(
                          'okEndDate',
                          7,
                          'American Travel Survey v1'
                        );}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('validation-provider',{attrs:{"name":"Trip Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","items":_vm.tripTypes,"error-messages":errors,"label":"Trip Type"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'tripType',
                        8,
                        'American Travel Survey v1'
                      )}},model:{value:(_vm.tripType),callback:function ($$v) {_vm.tripType=$$v},expression:"tripType"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Travellers ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Travelers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","items":_vm.travelers,"error-messages":errors,"label":"Travelers to insure"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'numberOfTravelers',
                        9,
                        'American Travel Survey v1'
                      )}},model:{value:(_vm.numberOfTravelers),callback:function ($$v) {_vm.numberOfTravelers=$$v},expression:"numberOfTravelers"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('validation-provider',{attrs:{"name":"Living in","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.StateList,"item-text":"name","item-value":"code","error-messages":errors,"hint":"Travelers living in a different state should purchase separate policies.","persistent-hint":"","label":"Living in"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'living',
                        10,
                        'American Travel Survey v1'
                      )}},model:{value:(_vm.living),callback:function ($$v) {_vm.living=$$v},expression:"living"}})]}}])})],1)],1),(_vm.numberOfTravelers > 0)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Primary Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Primary Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Primary Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          11,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[0]),callback:function ($$v) {_vm.$set(_vm.ages, 0, _vm._n($$v))},expression:"ages[0]"}})]}}],null,false,2471098853)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Primary Traveler Trip Cost","rules":"between:1,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"prefix":"$","outlined":"","label":"Primary Traveler Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          12,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[0]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 0, _vm._n($$v))},expression:"tripCosts[0]"}})]}}],null,false,1343865356)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 1)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Second Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Second Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Second Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          13,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[1]),callback:function ($$v) {_vm.$set(_vm.ages, 1, _vm._n($$v))},expression:"ages[1]"}})]}}],null,false,1128544090)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Second Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          14,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[1]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 1, _vm._n($$v))},expression:"tripCosts[1]"}})]}}],null,false,2030536696)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 2)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Third Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Third Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Third Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          15,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[2]),callback:function ($$v) {_vm.$set(_vm.ages, 2, _vm._n($$v))},expression:"ages[2]"}})]}}],null,false,887449516)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Third Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          16,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[2]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 2, _vm._n($$v))},expression:"tripCosts[2]"}})]}}],null,false,1190949721)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 3)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Fourth Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Fourth Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Fourth Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          17,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[3]),callback:function ($$v) {_vm.$set(_vm.ages, 3, _vm._n($$v))},expression:"ages[3]"}})]}}],null,false,1971256830)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Fourth Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          18,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[3]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 3, _vm._n($$v))},expression:"tripCosts[3]"}})]}}],null,false,413027894)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 4)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Fifth Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Fifth Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Fifth Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          19,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[4]),callback:function ($$v) {_vm.$set(_vm.ages, 4, _vm._n($$v))},expression:"ages[4]"}})]}}],null,false,4164875088)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Fifth Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          20,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[4]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 4, _vm._n($$v))},expression:"tripCosts[4]"}})]}}],null,false,2216382298)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 5)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Sixth Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Sixth Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Sixth Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          21,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[5]),callback:function ($$v) {_vm.$set(_vm.ages, 5, _vm._n($$v))},expression:"ages[5]"}})]}}],null,false,3192710929)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Sixth Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          22,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[5]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 5, _vm._n($$v))},expression:"tripCosts[5]"}})]}}],null,false,1837978425)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 6)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Seventh Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Seventh Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Seventh Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          23,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[6]),callback:function ($$v) {_vm.$set(_vm.ages, 6, _vm._n($$v))},expression:"ages[6]"}})]}}],null,false,554675161)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Seventh Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          24,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[6]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 6, _vm._n($$v))},expression:"tripCosts[6]"}})]}}],null,false,1724746396)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 7)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Eighth Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Eighth Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Eighth Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          25,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[7]),callback:function ($$v) {_vm.$set(_vm.ages, 7, _vm._n($$v))},expression:"ages[7]"}})]}}],null,false,2917742422)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Eighth Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          26,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[7]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 7, _vm._n($$v))},expression:"tripCosts[7]"}})]}}],null,false,448909567)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 8)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Nineth Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Nineth Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Nineth Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          27,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[8]),callback:function ($$v) {_vm.$set(_vm.ages, 8, _vm._n($$v))},expression:"ages[8]"}})]}}],null,false,1606681332)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Nineth Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          28,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[8]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 8, _vm._n($$v))},expression:"tripCosts[8]"}})]}}],null,false,1815574494)})],1)],1)],1):_vm._e(),(_vm.numberOfTravelers > 9)?_c('div',[_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10","md":"9"}},[_c('p',{staticClass:"secondary--text text-h4 font-weight-bold"},[_vm._v(" Tenth Traveler ")])])],1),_c('v-row',{staticClass:"d-flex justify-center my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Tenth Traveler Age","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","label":"Tenth Traveler Age","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'age',
                          29,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.ages[9]),callback:function ($$v) {_vm.$set(_vm.ages, 9, _vm._n($$v))},expression:"ages[9]"}})]}}],null,false,1034590952)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Tenth Traveler Trip Cost","rules":"between:0,50000|numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"prefix":"$","outlined":"","label":"Individual Trip Cost","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                          'cost',
                          30,
                          'American Travel Survey v1'
                        )}},model:{value:(_vm.tripCosts[9]),callback:function ($$v) {_vm.$set(_vm.tripCosts, 9, _vm._n($$v))},expression:"tripCosts[9]"}})]}}],null,false,3614947446)})],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"9","lg":"9"}},[_c('p',{staticClass:"btm-txt"},[_vm._v(" Travel insurance plans on this website are only available to U.S. residents. Generali Global Assistance is unable to provide coverage for travel to Russia, Belarus, Crimea, and the Donetsk and Luhansk People’s regions, Cuba, Iran, North Korea, Myanmar, Afghanistan and Syria. "),_c('br'),_c('br'),_vm._v(" For any of our U.S. customers traveling in Ukraine, please be advised that Generali's assistance capabilities in this country are substantially limited at this time. ")])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex text-center",class:_vm.mobileDevice ? 'justify-center' : 'justify-end'},[_c('v-btn',{staticClass:"mb-2 font-weight-bold",attrs:{"x-large":"","color":"primary"},on:{"click":function($event){_vm.next();
                      _vm.clickedFieldGlobal(
                        'getAQuote',
                        31,
                        'American Travel Survey v1'
                      );}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-airplane ")]),_vm._v(" Get A Quote ")],1)],1)])],1),(_vm.loading)?_c('v-overlay',[_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('ellipse-progress',{attrs:{"loading":_vm.loading,"size":180,"img-src":"src/assets/Travel-Icon.png","thickness":"5","empty-thickness":"3","line-mode":"out 5","legend":"false","animation":"rs 700 1000","font-size":"1.5rem","color":"#00A1B7","empty-color-fill":"transparent","filename":"Travel-Icon.png"}})],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dateErrorDialog),callback:function ($$v) {_vm.dateErrorDialog=$$v},expression:"dateErrorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text font-weight-bold"},[_vm._v(" Date Error ")]),_c('v-card-text',{staticClass:"d-flex justify-center text-center"},[_vm._v(" The trip end date must be after the start date. Please choose a different date. ")]),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.dateErrorDialog = false;
                      _vm.resetDate();
                      _vm.clickedFieldGlobal(
                        'closeDateErrorDialog',
                        32,
                        'American Travel Survey v1'
                      );}}},[_vm._v(" Close ")])],1)],1)],1),_c('error-dialog',{attrs:{"error-dialog":_vm.errorDialog,"error-message":_vm.errorMessage}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }